.separator {
    align-items: center;
    display: flex;
    text-align: center;
    height: 40px;
}
.separator:after {
    margin-left: 2.25rem;
}
.separator:before {
    margin-right: 2.25rem;
}
.separator:after,
.separator:before {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
}

.valign-wrapper {
    display: flex;
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.settings-page {
    padding-bottom: 60px;
}

ul {
    list-style: none;
}

.pointer {
    cursor: pointer;
}

.max-width-320 {
    max-width: 320px;
}