.skillImage {
    padding: 7px;
    border: 1px solid #9e9e9e;
    border-radius: 6px;
    width: 100%;
    max-width: 52px;
}

.width-70 {
    width: 70px;
}

.default-width {
    width: 52px;
}

.default-width.small {
    width: 40px;
    height: 40px;
}